// Fonts
@import url('https://fonts.googleapis.com/css?family=Open+Sans');
// Variables
@import 'variables';
// Bootstrap
@import '~bootstrap/scss/bootstrap';
// FontAwesome
@import '~@fortawesome/fontawesome-free/css/all.css';
// Vue Autocomplete
@import '~v-autocomplete/dist/v-autocomplete.css';

@import '~flatpickr/dist/flatpickr.css';

.banner-text {
    font-weight: bold;
    font-size: 3em;
}

.banner-logo {
    max-height: 100px;
}

img {
    @extend .img-fluid;
}

.sidebar {
    // Override collapse behaviors
    @include media-breakpoint-up(md) {
        display: block !important;
    }
}

.facebook-logo {
    max-height: 20px;
}

table {
    @extend .table;
}

h2 > a {
    text-decoration: none;
}

.icon-box {
    min-width: 50px;
}
